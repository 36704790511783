import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const WWGuidesModes: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game Modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_modes.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Game Modes</h1>
          <h2>
            All game modes available in Wuthering Waves - learn what awaits you
            in the game!
          </h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Game Modes" />
        <p>
          In addition to exploring the overworld and doing missions, Wuthering
          Waves also offers a number of other game modes for the player to enjoy
          as well.
        </p>
        <SectionHeader title="Resource / Boss Stages" />
        <p>
          Throughout the overworld in Wuthering Waves, you will encounter a
          number of dungeon type entrances or special fields that you can enter
          to farm resources and materials to progress your account.{' '}
          <strong>
            However, Waveplate (stamina) must be expended in order to obtain the
            rewards for completing these stages.
          </strong>
        </p>
        <p>The different stages are:</p>
        <h5>Simulation Field</h5>
        <StaticImage src="../../../images/ww/generic/mode_4.webp" alt="Guide" />
        <p>
          These are your basic resource stages and provide one of the following
          material depending on which Simulation Field you are farming:
        </p>
        <ul>
          <li>Simulation: Resonance Potion - Resonator EXP Material</li>
          <li>Simulation: Energy Core - Weapon EXP Material</li>
          <li>Simulation: Shell Credits - Shell Credits (in game money)</li>
          <li>Simulation: Sealed Tube - Echo EXP Material</li>
        </ul>
        <p>
          It costs 40 <strong>Waveplate</strong> to do one Simulation Field.
        </p>
        <h5>Forgery Challenge</h5>
        <StaticImage src="../../../images/ww/generic/mode_8.webp" alt="Guide" />
        <p>
          These stages reward Weapon Ascension/Forte Upgrade materials. There
          are 5 stages that drop different materials in correspondence with the
          5 different Weapon types, so make sure to check your Resonator page to
          see which one you need.
        </p>
        <p>
          It costs <strong>40 Waveplate</strong> to do one Forgery Challenge.
        </p>
        <h5>Boss Challenge</h5>
        <StaticImage src="../../../images/ww/generic/mode_6.webp" alt="Guide" />
        <p>
          These are Bosses that reward Resonator ascension materials. Each boss
          drops a different ascension material, so make sure to check your
          Resonator page to see which one the Resonator you are ascending needs.
        </p>
        <p>Those bosses restart 3 minutes after being killed.</p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Boss
          Challenge one time.
        </p>
        <h5>Tacet Field Cleanup</h5>
        <StaticImage src="../../../images/ww/generic/mode_2.webp" alt="Guide" />
        <p>
          These fields reward Echo Tune-up materials, Echoes, and Echo EXP
          materials. Each field reward Echoes with different Sonata(Set) Effects
          so make sure pick the ones with the Sonata Effects you want.
        </p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Tacet
          Field Cleanup one time.
        </p>
        <h5>Weekly Challenge</h5>
        <StaticImage src="../../../images/ww/generic/mode_5.webp" alt="Guide" />
        <p>
          These are weekly bosses that reward special Forte upgrade materials,
          standard weapon molds (used for weapon crafting), Weapon EXP
          materials, and Echo EXP materials.
        </p>
        <p>You can only claim rewards from these bosses once a week. </p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Weekly
          Challenge once.
        </p>
        <SectionHeader title="Tower of Adversity" />
        <StaticImage src="../../../images/ww/generic/mode_1.webp" alt="Guide" />
        <p>
          <strong>Tower of Adversity</strong> is a game mode where the objective
          is to defeat a group of enemies as fast as possible. After completing
          a Tower of Adversity stage you will be awarded between 0 to 3 crests
          for that stage depending on how long it took you to defeat the enemies
          in that stage. The more crests that you are awarded, the better the
          rewards you receive.
        </p>
        <p>
          It is a more challenging game mode with the{' '}
          <strong>final stages being considered as end game content</strong>.
        </p>
        <p>
          Tower of Adversity is divided into three parts:{' '}
          <strong>Stable Zone, Experimental Zone, and Hazard zone.</strong>
        </p>
        <ul>
          <li>
            <strong>Stable Zone</strong> consists of 4 stages and provides a one
            time reward of Astrites upon completion.
          </li>
          <li>
            <strong>Experimental Zone</strong> consists of 8 stages and also
            provides a one time reward of Astrites upon completion.
          </li>
          <ul>
            <li>
              It is not possible to clear all stages using the same Resonators
              due to Vigor limitations (explained below).
            </li>
          </ul>
          <li>
            <strong>Hazard Zone</strong> consists of 10 stages and reward
            Astrites upon completion.
          </li>
          <ul>
            <li>Hazard Zone stages are more challenging than other zones.</li>
            <li>
              Hazard Zone stages reset periodically, allowing the player to
              complete them again for additional Astrites.
            </li>
          </ul>
        </ul>
        <p>
          <strong>
            Basically, this mode is the Wuthering Waves equivalent of Abyss from
            Genshin Impact or Memory of Chaos from Honkai: Star Rail.
          </strong>
        </p>
        <h5>Vigor</h5>
        <StaticImage src="../../../images/ww/generic/mode_3.webp" alt="Guide" />
        <p>
          Vigor is a stamina system unique to every zone in the Tower of
          Adversity.
        </p>
        <ul>
          <li>Each Resonator starts with 10 Vigor.</li>
          <li>
            Each stage in a zone has a Vigor cost requirement. Selected
            Resonators’ Vigor will be reduced by the stage’s Vigor cost upon
            completing the stage.
          </li>
          <li>
            Resonators that don’t have enough Vigor for a stage cannot be
            selected for that stage.
          </li>
        </ul>
        <p>Here's a video showcasing the mode:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="i_kHN0y8zvQ" />
          </Col>
        </Row>
        <SectionHeader title="Depths of Illusive Realm" />
        <StaticImage src="../../../images/ww/generic/mode_7.webp" alt="Guide" />
        <p>
          <strong>
            Depths of Illusive Realm is a roguelike game mode where the player
            will select a Resonator to venture inside a simulated world to
            collect a random set of buffs that they can use to enhance their
            team and fight the enemies inside.
          </strong>{' '}
          The player will progress through a set of semi-randomized combat and
          non-combat stages with the goal of defeating the boss in the final
          stage. This game mode does not require the player to spend any
          Waveplate to play it and there is no limit to how many times the
          player can start a run in the Depths of Illusive Realm.
        </p>
        <p>
          Players can earn rewards such as Astrites and upgrade materials
          periodically via this game mode. Note that this game mode is currently
          in beta and it is highly possible that it will see drastic changes
          upon actual release.
        </p>
        <p>
          <strong>
            Basically, this mode is the Wuthering Waves equivalent of Simulated
            Universe from Honkai: Star Rail.
          </strong>
        </p>
        <p>Here's a video showcasing the mode:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="8ar2l5dLbIA" />
          </Col>
        </Row>
        <SectionHeader title="Tactical Hologram" />
        <h5>Tactical Hologram</h5>
        <p>
          Tactical Holograms are 1-time boss challenges with increasing levels
          of difficulty.
        </p>
        <ul>
          <li>
            There are currently 4 different bosses each with 6 difficulty
            levels.
          </li>
          <li>
            Clearing these boss challenges for the first time will reward you
            with various Echo related materials and Drill Data Sets.
          </li>
          <li>
            You can use these Drill Data Sets in store to obtain more Echo
            related materials and Astrites!
          </li>
        </ul>
        <p>Here's a video showcasing the mode:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ao67G1WRNyE" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesModes;

export const Head: React.FC = () => (
  <Seo
    title="Game Modes | Wuthering Waves | Prydwen Institute"
    description="All game modes available in Wuthering Waves - learn what awaits you in the game!"
    game="ww"
  />
);
